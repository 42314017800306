import React from 'react'
import Post from './Post'
import Tags from '../tags/Tags'
import Archives from '../archives/Archives'
import ContentPaging from '../contentPaging/ContentPaging'
import Style from './layoutPost.module.scss'

const Layout = ({
  title,
  imgObj,
  tags,
  date,
  content,
  archiveList,
  tagItems,
  nextTitle,
  nextPath,
  prevTitle,
  prevPath,
}) => (
  <div className={Style.layoutPostRoot}>
    <div className={Style.layoutPostContent}>
      <Post
        title={title}
        imgObj={imgObj}
        tags={tags}
        date={date}
        content={content}
      />
      <div className={Style.layoutPaging}>
        <ContentPaging
          nextTitle={nextTitle}
          nextPath={nextPath}
          prevTitle={prevTitle}
          prevPath={prevPath}
        />
      </div>
    </div>
    <div className={Style.layoutMenu}>
      <div>
        <Tags items={tagItems} />
      </div>
      <div className={Style.layoutArchive}>
        <Archives list={archiveList} />
      </div>
    </div>
  </div>
)

export default Layout
