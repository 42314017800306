import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Style from './contentPaging.module.scss'
import LeftIcon from '../../img/icon-left.svg'

const ContentPaging = ({ nextPath, nextTitle, prevPath, prevTitle }) => {
  const nextLink = nextPath
    ? `${Style.linkStyle} ${Style.nextLink}`
    : `${Style.hidden} ${Style.linkStyle} ${Style.nextLink}`
  const prevLink = prevPath
    ? `${Style.linkStyle}`
    : ` ${Style.linkStyle} ${Style.hidden}`
  return (
    <div className={Style.contentPagingRoot}>
      <div className={nextLink}>
        <Link className={Style.linkButton} to={nextPath ?? '/'}>
          <img src={LeftIcon} alt="Left icon" />
          <p className={Style.postContent}>{nextTitle}</p>
        </Link>
      </div>
      <div className={prevLink}>
        <Link className={Style.linkButton} to={prevPath ?? '/'}>
          <img src={LeftIcon} alt="Right icon" className={Style.rightIcon} />
          <p className={Style.postContent}>{prevTitle}</p>
        </Link>
      </div>
    </div>
  )
}

ContentPaging.propTypes = {
  prevTitle: PropTypes.string,
  nextTitle: PropTypes.string,
  prevPath: PropTypes.string,
  nextPath: PropTypes.string,
}

export default ContentPaging
