import React from 'react'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import moment from 'moment-timezone'
import LayoutPost from '../components/layoutPost'
import ArchivesData from '../staticdata/ArchivesData'
import TagsData from '../staticdata/TagsData'
import { useFirebase } from '../components/firebase/FirebaseContext'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const BlogPost = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: {
        title,
        tags,
        date,
        description,
        image: {
          publicURL,
          childImageSharp: { fluid },
        },
      },
      fields: { slug },
    },
  },
  pageContext: { next, previous },
}) => {
  useFirebase(firebase => {
    firebase.analytics().setCurrentScreen(window.location.pathname)
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={`${process.env.WEB_URL}${slug}`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${process.env.WEB_URL}${publicURL}`}
        />
      </Helmet>
      <Helmet
        script={[
          helmetJsonLdProp({
            '@context': 'https://schema.org',
            '@type': 'Article',
            dateModified: date,
            datePublished: date,
            headline: title,
            image: [`${process.env.WEB_URL}${publicURL}`],
          }),
        ]}
      />
      <Header activeType={0} />
      <LayoutPost
        title={title}
        imgObj={fluid}
        date={moment(date).format('YYYY/MM/DD')}
        tags={tags.map(tag => {
          return { name: tag }
        })}
        prevTitle={next ? next.frontmatter.title : null}
        nextTitle={previous ? previous.frontmatter.title : null}
        prevPath={next ? next.fields.slug : null}
        nextPath={previous ? previous.fields.slug : null}
        tagItems={TagsData()}
        archiveList={ArchivesData()}
        content={html}
      />
      <Footer />
    </>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        tags
        date
        image {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
