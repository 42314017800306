import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import RelatedTags from '../relatedTags/RelatedTags'
import PostDate from '../postDate'
import Style from './post.module.scss'
import { HTMLContent } from '../Content'
import MarkdownContent from '../markdownContent'

const Post = ({ title, imgObj, tags, date, content }) => {
  return (
    <article>
      <div>
        <Img
          className={Style.postImage}
          imgStyle={{ objectFit: 'contain' }}
          fluid={imgObj}
        />
      </div>
      <h1 className={Style.postTitle}>{title}</h1>
      <div className={Style.relatedTags}>
        <RelatedTags tags={tags} />
      </div>
      <div>
        <PostDate text={date} />
      </div>
      <div className={Style.contentRoot}>
        <MarkdownContent content={content} contentComponent={HTMLContent} />
      </div>
    </article>
  )
}

Post.propTypes = {
  title: PropTypes.string.isRequired,
  imgObj: PropTypes.object,
  tags: PropTypes.array,
  date: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default Post
